import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import Calendar from 'react-calendar';
import { getDayOfWeek } from 'react-calendar/dist/umd/shared/dates';
import { useMediaQuery } from 'react-responsive';
import {
  addDays, endOfWeek, getMonth, getYear, startOfWeek,
} from 'date-fns';
import { useRouter } from 'next/router';
import getSeasonColor from '../../../src/utils/search/getSeasonColor';
import useWeekId from '../../../src/hooks/useWeekId';

import styles from './WeeksPicker.module.scss';

function WeeksPicker({
  offers, weeks, packages, isWeekendMode, selectedWeeks, setSelectedWeeks, setTabsNeedUpdate,
}) {
  const { locale } = useRouter();
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  const { getDateFromWeekId, getWeekIdFromDate } = useWeekId();

  const [activeStartDay, setActiveStartDay] = useState(new Date());

  useEffect(() => {
    if (selectedWeeks?.length) {
      const sortedWeeks = selectedWeeks.sort((a, b) => a - b);
      let nearestSelectedDay = getDateFromWeekId(sortedWeeks[0]); // monday
      if (isWeekendMode) nearestSelectedDay = addDays(nearestSelectedDay, 4); // friday

      const nearestMonth = getMonth(nearestSelectedDay);
      const nearestYear = getYear(nearestSelectedDay);
      const activeMonth = getMonth(activeStartDay);
      const activeYear = getYear(activeStartDay);

      if (
        nearestYear > activeYear
        || (nearestYear === activeYear && nearestMonth > activeMonth)
      ) {
        setActiveStartDay(nearestSelectedDay);
      }
    }
  }, [selectedWeeks]);

  const handleWeekSelect = (input) => {
    const selectedWeek = getWeekIdFromDate(input);

    if (selectedWeeks.some((week) => week === selectedWeek)) {
      const updatedWeeks = [...selectedWeeks];
      updatedWeeks.splice(selectedWeeks.indexOf(selectedWeek), 1);
      setSelectedWeeks(updatedWeeks);
    } else {
      setSelectedWeeks([...selectedWeeks, selectedWeek]);
    }
  };

  const getTileClassName = (date) => {
    let className = '';
    const tileWeekId = getWeekIdFromDate(date);
    const hasOffer = offers.some((offer) => offer.weekId === tileWeekId);
    const isWeekend = getDayOfWeek(date) === 5 || getDayOfWeek(date) === 6;
    const isActiveOrPastWeek = tileWeekId <= getWeekIdFromDate(new Date());

    if (hasOffer && !isActiveOrPastWeek) {
      // Tile is part of a week with an offer

      // Offers this week
      const weekOffers = offers.filter((offer) => offer.weekId === tileWeekId);
      // Packages this week (based on offers)
      const packageIds = weekOffers.map((offer) => offer.packageId);
      const weekPackages = packages.filter((p) => packageIds.includes(p.id));
      // At least one package for the selected slot (week/weekend)
      const hasPackage = isWeekendMode
        ? weekPackages.some((p) => p.durationId === 2) // weekend (2)
        : weekPackages.some((p) => (p.durationId === 1 || p.durationId === 3)); // week (1) and week nights (3)

      if (hasPackage) {
        if ((isWeekendMode && isWeekend) || (!isWeekendMode && !isWeekend)) className += ` ${styles.has_offer}`;

        // Season color (prices)
        const week = Object.values(weeks).find((w) => w.id === tileWeekId);
        const seasonId = week ? week.seasonId : null;
        const seasonColor = seasonId && getSeasonColor(seasonId) ? getSeasonColor(seasonId) : null;
        if (seasonColor) className += ` ${styles[`season_${seasonColor}`]}`;

        if (isWeekendMode) {
          // Weekend
          if (getDayOfWeek(date) === 5) className += ` ${styles.start_slot}`; // from saturday
          if (getDayOfWeek(date) === 6) className += ` ${styles.end_slot}`; // to sunday
        } else {
          // Week
          if (getDayOfWeek(date) === 0) className += ` ${styles.start_slot}`; // from monday
          if (getDayOfWeek(date) === 4) className += ` ${styles.end_slot}`; // to friday
        }
      }
    }

    // Selected week
    if (selectedWeeks.includes(getWeekIdFromDate(date))) className += ` ${styles.selected_week}`;

    // Check last week of month (and hide it if next month begins)
    const startWeek = startOfWeek(date, { weekStartsOn: 1 });
    const endWeek = endOfWeek(date, { weekStartsOn: 1 });
    const differentMonths = getMonth(startWeek) !== getMonth(endWeek);

    if (differentMonths) {
      [...Array(7)].forEach(() => {
        if (!className.includes('hide_tile')) className += ' hide_tile';
      });
    }

    return className;
  };

  const tileIsDisabled = (date) => {
    const tileWeekId = getWeekIdFromDate(date);
    const hasOffer = offers.some((offer) => offer.weekId === tileWeekId);
    const isWeekend = getDayOfWeek(date) === 5 || getDayOfWeek(date) === 6;
    const isActiveOrPastWeek = tileWeekId <= getWeekIdFromDate(new Date());

    return !hasOffer
    || isActiveOrPastWeek
    || (hasOffer && !isWeekendMode && isWeekend)
    || (hasOffer && isWeekendMode && !isWeekend);
  };

  useEffect(() => {
    setTabsNeedUpdate((update) => update + 1);
  }, [activeStartDay]);

  return (
    <Calendar
      className={styles.calendar}
      onChange={handleWeekSelect}
      showDoubleView={!isTablet}
      showFixedNumberOfWeeks={false}
      tileClassName={({ date }) => getTileClassName(date)}
      tileDisabled={({ date }) => tileIsDisabled(date)}
      activeStartDate={activeStartDay}
      onActiveStartDateChange={({ action, activeStartDate }) => {
        if (action === 'prev' || action === 'next') setActiveStartDay(activeStartDate);
      }}
      nextLabel=""
      prevLabel=""
      minDetail="month"
      maxDetail="month"
      defaultView="month"
      locale={locale}
    />
  );
}

WeeksPicker.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  weeks: PropTypes.shape({}).isRequired,
  packages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isWeekendMode: PropTypes.bool,
  selectedWeeks: PropTypes.arrayOf(PropTypes.number),
  setSelectedWeeks: PropTypes.func.isRequired,
  setTabsNeedUpdate: PropTypes.func,
};

WeeksPicker.defaultProps = {
  isWeekendMode: false,
  selectedWeeks: [],
  setTabsNeedUpdate: () => {},
};

export default WeeksPicker;
